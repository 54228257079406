import { useCallback, useState, memo } from 'react';
import { ToggleProps } from '@amzn/awsui-components-react/polaris/toggle';
import AppLayout, { AppLayoutProps } from '@amzn/awsui-components-react/polaris/app-layout';
import type { NonCancelableCustomEvent } from '@amzn/awsui-components-react/polaris/internal/events';
import TopNavigationBar from '../TopNavigationBar';
import { useLocation, Outlet } from 'react-router-dom';

const MainApp = (): JSX.Element => {
  const [checked, setChecked] = useState(false);

  const setToggleStatus = useCallback((event: NonCancelableCustomEvent<ToggleProps.ChangeDetail>) => {
    setChecked(event.detail.checked);
  }, []);

  const location = useLocation();

  const getContentType = (): AppLayoutProps.ContentType => {
    if (location.pathname.includes('recordAsset') || location.pathname.includes('uploadAssets')) return 'form';
    else return 'table';
  };

  return (
    <div className={checked ? 'awsui-polaris-dark-mode awsui-visual-refresh' : 'awsui-visual-refresh'}>
      <TopNavigationBar
        onToggle={setToggleStatus}
        checked={checked}
      />
      <AppLayout
        contentType={getContentType()}
        toolsHide={true}
        navigationHide={true}
        content={
          <>
            <Outlet />
          </>
        }
      />
    </div>
  );
};

MainApp.displayName = 'MainApp';
export default memo(MainApp);
