import axios from 'axios';
import GetAssetsForProjectResponse from '../models/GetAssetsForProjectResponse';
import GetPreSignedUrlResponse from '../models/GetPreSignedUrlResponse';
import ListProjectsResponse from '../models/ListProjectsResponse';
import ProjectRecord from '../models/ProjectRecord';
import ListProjectsByPageResponse from '../models/ListProjectsByPageResponse';
import GetProjectCountResponse from '../models/GetProjectCountResponse';
import UpdateAssetRequest from '../models/UpdateAssetRequest';
import UpdateAssetResponse from '../models/UpdateAssetResponse';
import FileUploadStatusResponse from '../models/FileUploadStatusResponse';
import CreateAssetRecordRequest from '../models/CreateAssetRecordRequest';
import CreateAssetRecordResponse from '../models/CreateAssetRecordResponse';
import AssetRecord from '../models/AssetRecord';
import UpdateBulkAssetsRequest from '../models/UpdateBulkAssetsRequest';
import UpdateBulkAssetsResponse from '../models/UpdateBulkAssetsResponse';

export const getAssetsForProject = async (
  projectId: string,
  nextToken: string | null,
  pagesToGet: number,
): Promise<GetAssetsForProjectResponse> => {
  // URI reference for api https://tiny.amazon.com/15x2akd45/codeamazpackDeloblob7d78mode
  let uri = `/api/projects/${projectId}/assets?pagesToGet=${pagesToGet}`;

  if (nextToken) {
    uri += `&nextToken=${nextToken}`;
  }

  return await axios
    .get(uri, { withCredentials: true })
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
};

// Get all the records for a given projectId
export const getAllAssetsForProject = async (projectId: string): Promise<AssetRecord[]> => {
  const allAssets: AssetRecord[] = [];
  let nextToken = null;
  do {
    let uri = `/api/projects/${projectId}/assets?pagesToGet=10`;

    if (nextToken) {
      uri += `&nextToken=${nextToken}`;
    }
    const response: GetAssetsForProjectResponse = await axios
      .get(uri, { withCredentials: true })
      .then(response => response.data);
    allAssets.push(...response.assets);

    nextToken = response.nextToken;
  } while (nextToken != null);

  return allAssets;
};

export const getPreSignedUrl = async (
  projectId: string,
  fileName: string,
  checkSum: string,
): Promise<GetPreSignedUrlResponse> => {
  // URI reference https://tiny.amazon.com/14lbgfba4/codeamazpackDeloblobaf4amode
  return await axios
    .get(`/api/projects/${projectId}/presignedUrl?fileName=${fileName}&checksum=${checkSum}`, {
      withCredentials: true,
    })
    .then(response => response.data);
};

export const getUserCredentials = async (authCode: string | null, redirectUrl: string) => {
  return await axios
    .get(`/api/authenticate?authcode=${authCode}&redirecturl=${redirectUrl}`, { withCredentials: true })
    .then(response => response);
};

export const getAllMakes = async (): Promise<string[]> => {
  return await axios.get(`/api/manufacturers`, { withCredentials: true }).then(response => response.data.manufacturers);
};

export const getAllModelsForMake = async (make: string): Promise<string[]> => {
  return await axios
    .get(`/api/manufacturers/${make}/models`, { withCredentials: true })
    .then(response => response.data.models);
};

export const getMakeModelDetails = async (make: string, model: string): Promise<string> => {
  return await axios
    .get(`/api/manufacturers/${make}/models/${model}`, { withCredentials: true })
    .then(response => response.data.category);
};

export const getAllFbns = async (projectId: string): Promise<string[]> => {
  return await axios
    .get(`/api/projects/${projectId}/fbnbuildids`, { withCredentials: true })
    .then(response => response.data.fbnBuildIds);
};

const listProjectsByPage = async (pageNumber: number, pageSize: number): Promise<ListProjectsByPageResponse> => {
  return await axios
    .get(`/api/projects?&pageNumber=${pageNumber}&pageSize=${pageSize}`, { withCredentials: true })
    .then(response => response.data);
};

export const listProjects = async (): Promise<ListProjectsResponse> => {
  let pageNumber = 1;
  let pageSize = 300;
  const projectList: ProjectRecord[] = [];
  while (pageNumber !== 0) {
    const { currentPageNumber: currentPage, projects: projects } = await listProjectsByPage(pageNumber, pageSize);
    if (projects.length !== 0) {
      projectList.push(...projects);
      pageNumber = currentPage + 1;
    } else {
      pageNumber = 0;
    }
  }
  return { projects: projectList };
};

export const getProjectCountByStatus = async (projectId: string, status: string): Promise<GetProjectCountResponse> => {
  return await axios
    .get(`/api/projects/${projectId}/summary?assetStatus=${status}`, { withCredentials: true })
    .then(response => response.data);
};
export const updateAssetRequest = async (
  projectId: string,
  assetId: string,
  data: UpdateAssetRequest,
): Promise<UpdateAssetResponse> => {
  return await axios
    .put(`/api/projects/${projectId}/assets/${assetId}`, data, { withCredentials: true })
    .then(response => response.data);
};

export const getFileUploadStatus = async (projectId: string): Promise<FileUploadStatusResponse> => {
  return await axios
    .get(`/api/projects/${projectId}/uploads`, { withCredentials: true })
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
};

export const createAssetRecord = async (
  projectId: string,
  data: CreateAssetRecordRequest,
): Promise<CreateAssetRecordResponse> => {
  return await axios
    .post(`/api/projects/${projectId}/assets`, data, { withCredentials: true })
    .then(response => response.data);
};

export const bulkAssetsUpdate = async (
  projectId: string,
  bulkUpdate: UpdateBulkAssetsRequest,
): Promise<UpdateBulkAssetsResponse> => {
  return await axios
    .put(`/api/projects/${projectId}/assets`, bulkUpdate, { withCredentials: true })
    .then(response => response.data);
};
