import { memo } from 'react';
import '@amzn/awsui-global-styles/polaris.css';
import { AssetUploadPage, ProjectAssetsTable, ProjectsPage, RecordAssetPage, EditAssetsPage } from '../../lazy';
import { ProcoreLandingPage } from '@amzn/delonix-ui-procore-utils/ProcoreLandingPage';
import { ProcoreOAuth } from '@amzn/delonix-ui-procore-utils/ProcoreOAuth';
import ProcoreSuccess from '@amzn/delonix-ui-procore-utils/ProcoreSuccess';
import UserCredentialsWrapper from '../UserCredentialsWrapper';
import { Routes, Route } from 'react-router-dom';
import { Suspense } from 'react';
import Spinner from '@amzn/awsui-components-react/polaris/spinner';
import {
  BASE_ASSETS_PATH,
  RECORD_ASSET_PATH,
  UPLOAD_ASSET_PATH,
  MAIN_APP,
  EDIT_ASSET_PATH,
  DOWNLOAD_ASSETS_PATH,
  EDIT_ASSETS_PATH,
  MAIN_APP_AUTHORISED,
  MAIN_APP_AUTHORIZE,
} from '../../common/constants';
import { PROCORE_OAUTH_CALL } from '@amzn/delonix-ui-procore-utils/common/constants';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';
import MainApp from '../MainApp';
const queryClient = new QueryClient();

const AppRouter = (): JSX.Element => {
  const location = useLocation();

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Suspense
          fallback={
            <div>
              <Spinner
                size="normal"
                variant="normal"
              />{' '}
              {`Loading...`}
            </div>
          }
        >
          <Routes>
            <Route
              index
              element={<ProcoreLandingPage />}
            />
            <Route
              path={PROCORE_OAUTH_CALL}
              element={<ProcoreOAuth />}
            />
            <Route
              path={MAIN_APP}
              element={<ProcoreSuccess />}
            />
            <Route
              path={MAIN_APP_AUTHORIZE}
              element={<UserCredentialsWrapper />}
            />
            <Route
              path={MAIN_APP_AUTHORISED}
              element={<MainApp />}
            >
              <Route
                index
                element={<ProjectsPage />}
              />
              <Route
                path={BASE_ASSETS_PATH}
                element={
                  <ProjectAssetsTable
                    type={location.state?.type}
                    message={location.state?.message}
                  />
                }
              />
              <Route
                path={RECORD_ASSET_PATH}
                element={<RecordAssetPage mode="Record" />}
              />
              <Route
                path={UPLOAD_ASSET_PATH}
                element={<AssetUploadPage mode="upload" />}
              />
              <Route
                path={DOWNLOAD_ASSETS_PATH}
                element={<AssetUploadPage mode="download" />}
              />
              <Route
                path={EDIT_ASSET_PATH}
                element={
                  <RecordAssetPage
                    mode="Edit"
                    asset={location.state?.asset}
                  />
                }
              />
              <Route
                path={EDIT_ASSETS_PATH}
                element={<EditAssetsPage assets={location.state?.assets} />}
              />
            </Route>
          </Routes>
        </Suspense>
      </QueryClientProvider>
    </>
  );
};

AppRouter.displayName = 'AppRouter';
export default memo(AppRouter);
